import React from "react"
import Layout from "../../markup/layout/layout"
import Seo from "../../markup/layout/seo"
import useFetch from "../../utils/fetch"
import { Circles } from "react-loader-spinner"

import Produs from "../../components/Pagina-Produs/Produs"
import { PageBanner } from "../../markup/layout/PageBanner"

const qs = require("qs")

function PaginaProdus({ slug }) {
  const query = qs.stringify(
    {
      filters: {
        Slug: {
          $eq: slug,
        },
      },
      populate: ["Gallery"],
    },
    {
      encodeValuesOnly: true, // prettify URL
    }
  )
  console.log('query ---- ', query)
  const urlRequest = `${process.env.STRAPI_ENDPOINT}/products?${query}`
  const { loading, error, strapi_response } = useFetch(urlRequest)

  if (loading)
    return (
      <div className="spinner">
        <Circles color="#00BFFF" height={80} width={80} />
      </div>
    )
  const pageNotFoundErr =
    strapi_response?.data?.length === 0 ? "Pagina nu exista" : ""
  if (error || strapi_response?.data?.length === 0)
    return (
      <p>
        Eroare....{error}
        <br />
        {pageNotFoundErr}
      </p>
    )


  console.log('ce avem noi aici ---- ', strapi_response)

  const solutionInfo = {
    Name: strapi_response?.data[0].attributes.Name,
    Description: strapi_response?.data[0].attributes.Description,
    Gallery: strapi_response?.data[0].attributes.Gallery,
    Slug: strapi_response?.data[0].attributes.Slug,
  }

  const pageTitle = strapi_response?.data[0].attributes.Name
  return (
    <Layout>
      <Seo title={pageTitle} />
      <PageBanner
        isHomePage={false}
        pageInfo={{
          title: pageTitle,
          parent: { title: "Solutii", slug: "/solutii" },
          hasParent: true,
        }}
      />
      <Produs solutionInfo={solutionInfo} />
      {/* <Solutii solutionInfo={solutionInfo} isArchivePage={false} /> */}
    </Layout>
  )
}

export default PaginaProdus
